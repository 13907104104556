import { render, staticRenderFns } from "./ka-user-profile-tab-notification-settings.vue?vue&type=template&id=0d7e0231&scoped=true&"
import script from "./ka-user-profile-tab-notification-settings.vue?vue&type=script&lang=js&"
export * from "./ka-user-profile-tab-notification-settings.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-profile-tab-notification-settings.vue?vue&type=style&index=0&id=0d7e0231&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d7e0231",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KAutocompleteTechnologies from '@web/components/core/k-autocomplete-technologies'
import KButton from '@web/components/core/k-button'
import KCheckbox from '@web/components/core/k-checkbox'
import KSelect from '@web/components/core/k-select'
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {KAutocompleteTechnologies,KButton,KCheckbox,KSelect,VContainer,VExpandTransition})
