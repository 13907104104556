<template>
    <v-container pa-0 ma-0 fluid>
        <h3 class="tw-heading-3">{{ $t('profile.subtitles.basic_settings') }}</h3>
        <hr class="kod-hr" />

        <v-row class="mt-5">
            <v-col cols="12">
                <div class="tw-flex tw-items-center tw-gap-x-4 tw-mb-4">
                    <ka-user-avatar :size="80" />

                    <div class="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row">
                        <k-button
                            color="secondary"
                            :loading="isState('avatar', 'PENDING')"
                            :success="isState('avatar', 'SUCCESS')"
                            @click="handleChangeAvatar()"
                            custom-class="tw-py-1 tw-px-4 tw-leading-relaxed"
                        >
                            {{ profile && profile.avatar ? $t('profile.actions.change_photo') : $t('profile.actions.upload_photo') }}
                        </k-button>

                        <k-button
                            v-show="profile && profile.avatar"
                            color="text"
                            :disabled="isState('avatar', 'PENDING')"
                            @click="$store.dispatch('USER/UPDATE_PROFILE', { avatar: null })"
                            custom-class="tw-py-1 tw-px-0 hover:tw-text-red hover:tw-bg-white tw-leading-relaxed"
                        >
                            {{ $t('profile.actions.delete_photo') }}
                        </k-button>
                    </div>
                </div>
                <p v-if="isState('avatar', 'ERROR')" class="tw-text-red-500 tw-mt-2">
                    {{ $t('global.form_errors.upload_photo_max_size_5mb') }}
                </p>
            </v-col>
        </v-row>

        <form @submit.prevent="submit" class="mt-2">
            <v-row>
                <v-col cols="12" sm="6">
                    <k-input v-model="firstName" :title="$t('global.inputs.first_name')" required :invalid="submittedProfile && !isValidField('firstName')" />
                </v-col>
                <v-col cols="12" sm="6">
                    <k-input v-model="lastName" :title="$t('global.inputs.last_name')" />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="8">
                    <k-input v-model="phoneNumber" :title="$t('global.inputs.phone')" pattern="^\+[1-9]\s*[\d\s*]+$" class="tw-w-full" />
                    <p class="tw-text-gray-700 tw-italic">{{ $t('global.form_rules.phone_number') }}</p>
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <k-combobox
                        v-model="profession"
                        :search-input.sync="profession"
                        :items="allProfessions"
                        :title="$t('global.inputs.profession')"
                        :invalid="submittedProfile && !isValidField('profession')"
                        required
                        item-value="value"
                        item-text="name"
                    />
                </v-col>
                <v-col cols="12">
                    <k-autocomplete-technologies v-model="technologies" :items="allTechnologies" :title="$t('profile.inputs.dominated_technologies')" :show-limit="3" />
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col>
                    <p class="tw-text-sm tw-font-bold tw-pb-2">{{ $t('profile.language') }}</p>
                    <k-select v-model="language" :items="languages" :placeholder="$t('profile.language')" />
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col>
                    <k-textarea :title="$t('profile.inputs.bio')" v-model="bio" rows="3" />
                </v-col>
            </v-row>

            <v-row class="mt-2">
                <v-col cols="12">
                    <p class="tw-text-sm tw-font-bold tw-pb-2">CV</p>
                    <div class="tw-flex tw-items-center tw-gap-x-4 tw-mb-4">
                        <k-button
                            v-if="profile.customCv"
                            :href="profile.customCv"
                            color="secondary"
                            custom-class="tw-py-1 tw-px-4 tw-leading-relaxed"
                        >
                            <k-icon name="download" />
                        </k-button>


                        <div class="tw-flex tw-flex-col tw-gap-4 sm:tw-flex-row">
                            <k-button
                                color="secondary"
                                :loading="isState('customCv', 'PENDING')"
                                :success="isState('customCv', 'SUCCESS')"
                                @click="handleChangeCV()"
                                custom-class="tw-py-1 tw-px-4 tw-leading-relaxed"
                            >
                                {{ profile && profile.customCv ? $t('profile.actions.change_custom_cv') : $t('profile.actions.upload_custom_cv') }}
                            </k-button>

                            <k-button
                                :to="{name: 'profile-cv'}"
                                v-show="profile && !profile.customCv"
                                color="secondary"
                                custom-class="tw-py-1 tw-px-4 tw-leading-relaxed"
                            >
                               {{ $t('profile.actions.create_koderia_cv') }}
                            </k-button>

                            <k-button
                                v-show="profile && profile.customCv"
                                color="text"
                                :disabled="isState('customCv', 'PENDING')"
                                @click="$store.dispatch('USER/UPDATE_PROFILE', { customCv: null })"
                                custom-class="tw-py-1 tw-px-0 hover:tw-text-red hover:tw-bg-white tw-leading-relaxed"
                            >
                                {{ $t('profile.actions.delete_custom_cv') }}
                            </k-button>
                        </div>
                    </div>
                    <p v-if="isState('avatar', 'ERROR')" class="tw-text-red-500 tw-mt-2">
                        {{ $t('global.form_errors.upload_photo_max_size_5mb') }}
                    </p>
                </v-col>
            </v-row>

            <v-expand-transition>
                <div v-show="isState('profile', 'ERROR')" class="mb-4 form-error-message">
                    <p>{{ formStateMessage.profile }}</p>
                </div>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-col cols="12">
                    <k-button
                        :loading="isState('profile', 'PENDING')"
                        :success="isState('profile', 'SUCCESS')"
                        color="primary"
                        type="submit"
                    >
                        {{ $t('global.actions.save') }}
                    </k-button>
                </v-col>
            </v-row>
        </form>

        <h3 class="mt-15 tw-heading-3">{{ $t('global.inputs.email') }}</h3>
        <hr class="kod-hr" />

        <form @submit.prevent="submitEmail" class="mt-8">
            <p>
                {{ $t('profile.inputs.current_email') }}: <strong>{{ currentEmail }}</strong>
            </p>

            <v-row class="mt-3">
                <v-col cols="12">
                    <k-input type="email" v-model="email" :title="$t('profile.inputs.new_email')" :invalid="submittedEmail && !isValidField('email')" class="tw-w-full" />
                </v-col>
            </v-row>

            <v-expand-transition>
                <div v-show="isState('email', 'ERROR')" class="mb-4 form-error-message">
                    <p>{{ formStateMessage.email }}</p>
                </div>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-col cols="12">
                    <k-button
                        color="primary"
                        :loading="isState('email', 'PENDING')"
                        :success="isState('email', 'SUCCESS')"
                        type="submit"
                    >
                        {{ $t('global.actions.save') }}
                    </k-button>
                </v-col>
            </v-row>
        </form>

        <h3 class="mt-15 tw-heading-3">{{ $t('global.inputs.password') }}</h3>
        <hr class="kod-hr" />

        <form @submit.prevent="submitPassword" class="mt-5">
            <v-row>
                <v-col cols="12">
                    <k-password v-model="password" :title="$t('profile.inputs.new_password')" :invalid="submittedPassword && !isValidField('password')" class="tw-w-full" />
                </v-col>
            </v-row>

            <v-expand-transition>
                <div v-show="isState('password', 'ERROR')" class="mb-4 form-error-message">
                    <p>{{ formStateMessage.password }}</p>
                </div>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-col cols="12">
                    <k-button
                        color="primary"
                        :loading="isState('password', 'PENDING')"
                        :success="isState('password', 'SUCCESS')"
                        type="submit"
                    >
                        {{ $t('global.actions.save') }}
                    </k-button>
                </v-col>
            </v-row>
        </form>

        <div class="mb-6 mt-13">
            <hr class="kod-hr" />
        </div>

        <v-dialog v-model="dialog" persistent max-width="640">
            <template v-slot:activator="{ on }">
                <a v-on="on" class="delete-account-link">{{ $t('global.actions.delete_account') }}</a>
            </template>
            <v-card class="dialog">
                <h3 class="medium-heading-text">{{ $t('profile.subtitles.delete_account') }}</h3>
                <hr class="kod-hr" />
                <p class="mt-8 body-text">{{ $t('profile.descriptions.account_cancellation_undone') }}</p>
                <div class="mt-10">
                    <k-button color="primary" @click="dialog = false">{{ $t('global.actions.back') }}</k-button>
                    <k-button color="text" custom-class="tw-py-1 tw-px-0 tw-text-red hover:tw-text-red-alt hover:tw-bg-white tw-leading-relaxed ml-4" @click="deleteAccount()">
                        {{ $t('global.actions.delete_account') }}
                    </k-button>
                </div>
            </v-card>
        </v-dialog>

        <v-overlay :value="isState('deleteAccount', 'PENDING')">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import KaUserAvatar from "../components/ka-user-avatar";
import {DEFAULT_LOCALE} from '@web/constants/language'

export default {
    components: {
        KaUserAvatar
    },
    data() {
        return {
            // Profile data
            firstName: null,
            lastName: null,
            phoneNumber: null,
            bio: null,
            profession: null,
            technologies: null,
            gender: null,
            // Data
            avatarFile: null,
            currentEmail: null,
            email: null,
            password: null,
            dialog: false,
            submittedProfile: false,
            submittedEmail: false,
            submittedPassword: false,
            showPassword: false,
            language: DEFAULT_LOCALE,
            languages: [
                {
                    value: "sk",
                    text: "Slovenský"
                },
                {
                    value: "en",
                    text: "Anglický"
                }
            ],
        };
    },
    computed: {
        user() {
            return this.$store.state.AUTH.user;
        },
        profile() {
            return this.$store.state.USER.profile;
        },
        formState: function() {
            return this.$store.state.USER.formState;
        },
        formStateMessage: function() {
            return this.$store.state.USER.formStateMessage;
        },
        ...mapGetters("ENUMS", {
            allProfessions: "allStacksNames",
            allTechnologies: "allTechnologiesNames"
        })
    },
    watch: {
        user: {
            immediate: true,
            handler() {
                this.currentEmail = this.user ? this.user.email : null;
            }
        },
        profile: {
            immediate: true,
            handler() {
                if (!this.profile) return;
                this.firstName = this.profile.firstName || null;
                this.lastName = this.profile.lastName || null;
                this.phoneNumber = this.profile.phoneNumber || null;
                this.bio = this.profile.bio || null;
                this.profession = this.profile.profession || null;
                this.technologies = this.profile.technologies || null;
                this.gender = this.profile.gender || null;
                this.language = this.profile.language || DEFAULT_LOCALE
            }
        },
        formState: {
            deep: true,
            handler(state) {
                Object.keys(state).forEach(name => {
                    if (state[name] === "SUCCESS") {
                        setTimeout(() => {
                            this.$store.commit("USER/SET_FORM_STATE", { name, state: "IDLE", message: null });
                        }, 2500);
                    }
                });
            }
        }
    },
    methods: {
        isValidField(fieldName) {
            switch (fieldName) {
                case "firstName":
                    return typeof this.firstName === "string" && this.firstName.trim().length > 0;
                case "profession":
                    return typeof this.profession === "string" && this.profession.trim().length > 0;
                case "email":
                    return typeof this.email === "string" && /(.+)@(.+){2,}\.(.+){2,}/.test(this.email);
                case "password":
                    return typeof this.password === "string" && this.password.trim().length > 5;
            }
        },

        submit() {
            this.submittedProfile = true;

            if (!this.isValidField("firstName")) {
                // this.$store.commit("USER/SET_FORM_STATE", {
                //     name: "profile",
                //     state: "ERROR",
                //     message: "Krstné meno musí byť vyplnené."
                // });
                return;
            } else if (!this.isValidField("profession")) {
                // this.$store.commit("USER/SET_FORM_STATE", {
                //     name: "profile",
                //     state: "ERROR",
                //     message: "Profesia musí byť vyplnená."
                // });
                return;
            }

            this.$store.dispatch("USER/UPDATE_PROFILE", {
                firstName: this.firstName || null,
                lastName: this.lastName || null,
                phoneNumber: this.phoneNumber ? String(this.phoneNumber).replace(/\s/g, "") : null,
                bio: this.bio || null,
                profession: this.profession || null,
                technologies: this.technologies || null,
                gender: this.gender || null,
                language: this.language
            });
            this.submittedProfile = false;
        },
        submitEmail() {
            this.submittedEmail = true;

            if (!this.isValidField("email")) {
                this.$store.commit("USER/SET_FORM_STATE", {
                    name: "email",
                    state: "ERROR",
                    message: this.$t('global.form_rules.not_valid_email')
                });
                return;
            }

            this.$store.dispatch("USER/UPDATE_EMAIL", this.email);
            this.submittedEmail = false;
            this.email = null;
        },
        submitPassword() {
            this.submittedPassword = true;

            if (!this.isValidField("password")) {
                this.$store.commit("USER/SET_FORM_STATE", {
                    name: "password",
                    state: "ERROR",
                    message: this.$t('global.form_rules.password_length')
                });
                return;
            }

            this.$store.dispatch("USER/UPDATE_PASSWORD", this.password);
            this.submittedPassword = false;
            this.password = null;
        },
        deleteAccount() {
            this.dialog = false;
            this.$store.dispatch("USER/DELETE_ACCOUNT");
        },
        handleChangeAvatar() {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", ".jpg,.jpeg,.png");
            input.onchange = e => {
                this.$store.dispatch("USER/UPLOAD_PROFILE_AVATAR", e.target.files[0]);
            };
            input.dispatchEvent(new MouseEvent("click"));
        },
        handleChangeCV() {
            console.log(this.$store)
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", ".pdf");
            input.onchange = e => {
                this.$store.dispatch("USER/UPLOAD_PROFILE_CUSTOM_CV", e.target.files[0]);
            };
            input.dispatchEvent(new MouseEvent("click"));
        },
        isState(name, state) {
            return this.formState[name] === state;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";

.mt-15 {
    margin-top: 3.75rem;
}

.mt-13 {
    margin-top: 3.25rem;
}

.kod-hr {
    margin-top: 1.063rem !important;
}

.delete-account-link {
    text-decoration: underline;
    color: $koderia-red;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: $koderia-red-alt;
    }
}

.form-error-message p {
    margin-top: 0 !important;
}

.dialog {
    padding: 4rem;
    border-radius: 0.125rem !important;
}

.avatar-actions {
    display: grid;
    grid-template-columns: 5rem 12.5rem 9.375rem;
    gap: 0.5rem;
    align-items: center;

    .avatar-actions__button--change {
        margin-left: 1rem;
    }

    @media (max-width: 40rem) {
        justify-items: center;

        .avatar-actions__button {
            width: 100%;
        }

        .avatar-actions__button--change {
            margin-top: 1rem;
            margin-left: 0;
        }

        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}
</style>
