<template>
    <v-container pa-0 ma-0 fluid>
        <h3 class="tw-heading-3">{{ $t('profile.subtitles.newsletter') }}</h3>
        <hr class="mb-8 kod-hr" />

        <k-checkbox v-model="newsletter" value="96171433f6" :label="$t('profile.inputs.new_jobs')" class="mt-8" />
        <p class="note">{{ $t('profile.descriptions.positions_based_on_technologies') }}</p>
        <v-expand-transition>
            <div v-show="newsletter && newsletter.indexOf('96171433f6') !== -1">
                <k-select
                    v-model="newsletterProfessions"
                    :search-input.sync="newsletterProfessions"
                    :items="allProfessions"
                    :title="$t('global.inputs.profession')"
                    multiple
                    class="mt-6"
                    item-text="name"
                    item-value="value"
                />

                <k-autocomplete-technologies v-model="newsletterTechnologies" :items="allTechnologies" :title="$t('global.inputs.technology')" :show-limit="5" class="mt-6" />
            </div>
        </v-expand-transition>

        <k-checkbox v-model="newsletter" value="225c3ce812" :label="$t('profile.inputs.koderia_news')" class="mt-8" />
        <p class="note">{{ $t('profile.descriptions.sending_news') }}</p>

        <k-checkbox v-model="newsletter" value="571c0a0a77" label="Koderia Blog" class="mt-8" />
        <p class="note">{{ $t('profile.descriptions.new_blog_articles') }}</p>

        <k-button
            color="primary"
            :loading="isState('profile', 'PENDING')"
            :success="isState('profile', 'SUCCESS')"
            class="mt-10"
            @click.native="subminNewsletter"
        >
            {{ $t('global.actions.save') }}
        </k-button>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            newsletter: [],
            newsletterProfessions: [],
            newsletterTechnologies: []
        };
    },
    computed: {
        profile() {
            return this.$store.state.USER.profile;
        },
        formState: function() {
            return this.$store.state.USER.formState;
        },
        formStateMessage: function() {
            return this.$store.state.USER.formStateMessage;
        },
        ...mapGetters("ENUMS", {
            allProfessions: "allStacksNames",
            allTechnologies: "allTechnologiesNames"
        })
    },
    watch: {
        profile: {
            immediate: true,
            handler() {
                if (!this.profile) return;

                const { newsletter, newsletterProfessions, newsletterTechnologies, profession, technologies } = this.profile || {};

                this.newsletter = newsletter || [];

                if (!(Array.isArray(newsletterProfessions) && newsletterProfessions.length > 0)) {
                    this.newsletterProfessions = profession ? [profession] : [];
                } else {
                    this.newsletterProfessions = newsletterProfessions || [];
                }

                if (!(Array.isArray(newsletterTechnologies) && newsletterTechnologies.length > 0)) {
                    this.newsletterTechnologies = Array.isArray(technologies) ? technologies : [];
                } else {
                    this.newsletterTechnologies = newsletterTechnologies || [];
                }
            }
        },
        formState: {
            deep: true,
            handler(state) {
                Object.keys(state).forEach(name => {
                    if (state[name] === "SUCCESS") {
                        setTimeout(() => {
                            this.$store.commit("USER/SET_FORM_STATE", { name, state: "IDLE" });
                        }, 2500);
                    }
                });
            }
        }
    },
    methods: {
        subminNewsletter() {
            this.$store.dispatch("USER/UPDATE_PROFILE", {
                newsletter: this.newsletter || [],
                newsletterProfessions: this.newsletterProfessions || [],
                newsletterTechnologies: this.newsletterTechnologies || []
            });
        },
        isState(name, state) {
            return this.formState[name] === state;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "@web/sass/variables.scss";
.note {
    color: $koderia-grey;
}

.kod-hr {
    margin-top: 1.063rem !important;
}
</style>
