import { render, staticRenderFns } from "./ka-user-profile-tab-profile-settings.vue?vue&type=template&id=07f6a687&scoped=true&"
import script from "./ka-user-profile-tab-profile-settings.vue?vue&type=script&lang=js&"
export * from "./ka-user-profile-tab-profile-settings.vue?vue&type=script&lang=js&"
import style0 from "./ka-user-profile-tab-profile-settings.vue?vue&type=style&index=0&id=07f6a687&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07f6a687",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import KAutocompleteTechnologies from '@web/components/core/k-autocomplete-technologies'
import KButton from '@web/components/core/k-button'
import KCombobox from '@web/components/core/k-combobox'
import KIcon from '@web/components/core/k-icon'
import KInput from '@web/components/core/k-input'
import KPassword from '@web/components/core/k-password'
import KSelect from '@web/components/core/k-select'
import KTextarea from '@web/components/core/k-textarea'
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {KAutocompleteTechnologies,KButton,KCombobox,KIcon,KInput,KPassword,KSelect,KTextarea,VCard,VCol,VContainer,VDialog,VExpandTransition,VOverlay,VProgressCircular,VRow})
